/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart'
// import { 
//   SpaceDashboard,
//   AdminPanelSettings, 
//   AccountCircle,
//   Lightbulb,
//   Inventory,
//   People,
//   Receipt,
//   Task,
//   EmojiEvents,
//   Inventory2,
//   Subscriptions,
//   Discount,
//   ShowChart,
//   BarChart,
//   Troubleshoot,
//   RssFeed,
//   Recommend
// } from '@mui/icons-material'

export function AsideMenuMain() {
  const intl = useIntl()
  const user_Role = useAuth().auth?.roles[0];
  return (
    <>      
        <AsideMenuItem
          to='/dashboard'
          // icon={<SpaceDashboard />}
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />     

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}
      {user_Role === "ROLE_ADMIN" &&
        <AsideMenuItemWithSub
          to=''
          title='Admin'
          fontIcon='bi-archive'
          // icon={<AdminPanelSettings />}
          icon='/media/icons/duotune/general/gen049.svg'
        >
          <AsideMenuItemWithSub
            to=''
            title='Reward MGMT'
            fontIcon='bi-archive'
            // icon={<EmojiEvents />}
            icon='/media/icons/duotune/general/gen020.svg'
          >
            <AsideMenuItem
              to='/reward-point-category'
              title='Reward Point Category Master'
              hasBullet={true}
              // icon='/media/icons/duotune/communication/com012.svg'
              fontIcon='bi-layers'
            />
            <AsideMenuItem
              to='/reward-point-plan'
              title='Reward Point Plan Master'
              hasBullet={true}
              // icon='/media/icons/duotune/communication/com005.svg'
              fontIcon='bi-layers'
            />
            <AsideMenuItem
              to='/reward-point-value'
              title='Reward Point Value Master'
              hasBullet={true}
              // icon='/media/icons/duotune/communication/com014.svg'
              fontIcon='bi-layers'
            />

          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/exchange'
            title='Exchange Master'
            // icon={<Inventory />}
            icon='/media/icons/duotune/communication/com009.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/indicies'
            title='Indicies Master'
            // icon={<Inventory2 />}
            icon='/media/icons/duotune/communication/com009.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/subscription-plan'
            title='Subscription Plan Master'
            // icon={<Subscriptions />}
            icon='/media/icons/duotune/communication/com011.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/discount-coupon'
            title='Discount Coupon Master'
            // icon={<Discount />}
            icon='/media/icons/duotune/general/gen018.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/user-live-strategy-paper'
            title='Strategy Stats'
            // icon={<Lightbulb />}
            icon='/media/icons/duotune/communication/com014.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/stock-analysis'
            title='Stock Analysis'
            // icon={<BarChart />}
            icon='/media/icons/duotune/communication/com014.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/stock-history'
            title='Stock Analysis 2'
            // icon={<Troubleshoot />}
            icon='/media/icons/duotune/communication/com014.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItemWithSub
            to=''
            title='Live Algo Transaction'
            fontIcon='bi-archive'
            // icon={<RssFeed />}
            icon='/media/icons/duotune/general/gen020.svg'
          >
            <AsideMenuItem
              to='/live-algo-transaction-exchange'
              title='Exchange Detail'
              hasBullet={true}
              // icon='/media/icons/duotune/communication/com014.svg'
              fontIcon='bi-layers'
            />
            <AsideMenuItem
              to='/live-algo-transaction-history'
              title='Transaction History'
              hasBullet={true}
              // icon='/media/icons/duotune/communication/com014.svg'
              fontIcon='bi-layers'
            />            
          </AsideMenuItemWithSub>         

          <AsideMenuItem
            to='/cross-reference'
            title='Cross Reference'
            // icon={<Recommend />}
            icon='/media/icons/duotune/communication/com014.svg'
            fontIcon='bi-layers'
          />

        </AsideMenuItemWithSub>
      }
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        // icon={<AccountCircle />}
        icon='/media/icons/duotune/general/gen021.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Profile' hasBullet={true} />
        {/* <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
        <AsideMenuItem to='/referral' title='Referral' hasBullet={true} />
        <AsideMenuItem to='/subscription' title='Subscriptions' hasBullet={true} />
        <AsideMenuItem
          to='/user-live-strategy'
          title='User Live Strategy'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/strategy'
        title='Strategy Marketplace'
        // icon={<Lightbulb />}
        icon='/media/icons/duotune/communication/com009.svg'
        fontIcon='bi-sticky'
      />
      <AsideMenuItem
        to='/client-exchange'
        title='Exchange/Broker'
        // icon={<Inventory />}
        icon='/media/icons/duotune/communication/com014.svg'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/multi-user-exchange'
        title='MultiUserExchange'
        // icon={<People />}
        icon='/media/icons/duotune/communication/com014.svg'
        fontIcon='bi-layers'
      />
      {user_Role === "ROLE_ADMIN" && (
        <>
          <AsideMenuItem
            to='/order-invoice'
            title='Order Invoice'
            // icon={<Receipt />}
            icon='/media/icons/duotune/communication/com014.svg'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/api/ticket/number'
            title='Ticket Generator'
            // icon={<Task />}
            icon='/media/icons/duotune/communication/com014.svg'
            fontIcon='bi-layers'
          />
        </>
      )
      }
      {/* <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
